import { Getter } from 'vuex'
import { RootState, RootGetters } from '@/store/types'
import isEmpty from '@/helpers/isEmpty'
import { computeMatrixOrigin } from '@/helpers/computeMatrixOrigin'

const resolveUrl = ({
  path,
  query = [],
}: {
  path: unknown[]
  query?: { key: string; value?: unknown }[]
}) => {
  return [
    path
      .filter((v) => !isEmpty(v))
      .concat('')
      .join('/'),
    query
      .filter(({ value }) => !isEmpty(value))
      .map(({ key, value }) => `${key}=${value}`)
      .join('&'),
  ]
    .filter((item) => item.trim().length > 0)
    .join('?')
}

const getMatrixUrl: Getter<RootState, RootState> = (
  _state,
  getters: RootGetters
) => {
  const getter: RootGetters['getMatrixUrl'] = (payload) => {
    const platformOrigin =
      payload.type == 'article' || payload.type === 'draft-article'
        ? computeMatrixOrigin({
            platform: getters.getPlatform,
            sectionCode: payload.params.sectionCode,
          })
        : getters.getPlatformOrigin

    switch (payload.type) {
      case 'draft-article':
        return resolveUrl({
          path: [platformOrigin, 'draft', payload.params.id],
          query: [{ key: 'accessHash', value: payload.params.accessHash }],
        })

      case 'article':
        return resolveUrl({
          path: [
            payload.params.platformOrigin || platformOrigin,
            `-${payload.params.id}`,
          ],
          query: [{ key: 'accessHash', value: payload.params.accessHash }],
        })

      case 'podcast':
        return resolveUrl({
          path: [platformOrigin, 'podcasts', payload.params.podcastCode],
        })

      case 'podcast-episode':
        return resolveUrl({
          path: [
            platformOrigin,
            'podcasts',
            payload.params.podcastCode,
            payload.params.episodeCode,
          ],
        })

      case 'tag':
        return resolveUrl({
          path: [platformOrigin, 'tag', payload.params.code],
        })

      case 'expert':
        return resolveUrl({
          path: [platformOrigin, 'experts', payload.params.code],
        })

      case 'recipe':
        return resolveUrl({
          path: [platformOrigin, 'recipes', payload.params.code],
        })

      case 'recipe-ingredient':
        return resolveUrl({
          path: [platformOrigin, 'recipes', 'ingredient', payload.params.code],
        })

      case 'recipe-category':
        return resolveUrl({
          path: [platformOrigin, 'recipes', 'category', payload.params.code],
        })

      case 'biography':
        return resolveUrl({
          path: [
            platformOrigin,
            sectionBio(getters.getPlatform),
            'biography',
            payload.params.code,
          ],
          query: [{ key: 'accessHash', value: payload.params.accessHash }],
        })

      case 'brands':
        return resolveUrl({
          path: [
            payload.params.platformOrigin || platformOrigin,
            `-${payload.params.code}`,
          ],
          query: [{ key: 'accessHash', value: payload.params.accessHash }],
        })

      case 'topic':
        return resolveUrl({
          path: [platformOrigin, 'topic', payload.params.code],
          query: [{ key: 'accessHash', value: payload.params.accessHash }],
        })

      case 'topicInterest':
        return resolveUrl({
          path: [platformOrigin, 'topic'],
          query: [{ key: 'interest', value: payload.params.code }],
        })

      case 'awards-story':
        return resolveUrl({
          path: [platformOrigin, 'awards', 'story', payload.params.code],
        })

      default:
        return ''
    }
  }

  return getter
}

const sectionBio = (platform: string) => {
  return ['cosmo', 'goodhouse'].includes(platform) ? 'stars' : 'persons'
}

export default getMatrixUrl
