import { VuetifyIconComponent } from 'vuetify/types/services/icons'

import arrowLeft from './arrow-left.vue'
import arrowRight from './arrow-right.vue'
import play from './play.vue'
import logoBazaar from './logo-bazaar.vue'
import logoCosmo from './logo-cosmo.vue'
import logoEsquire from './logo-esquire.vue'
import logoGoodhouse from './logo-goodhouse.vue'
import logoGrazia from './logo-grazia.vue'
import logoIm from './logo-im.vue'
import logoMhealth from './logo-mhealth.vue'
import logoPopmech from './logo-popmech.vue'
import logoRobb from './logo-robb.vue'
import home from './home.vue'
import content from './content.vue'
import comment from './comment.vue'
import design from './design.vue'
import analytics from './analytics.vue'
import distribution from './distribution.vue'
import distributionPlan from './distribution-plan.vue'
import marketing from './marketing.vue'
import moveDown from './move-down.vue'
import ad_target from './ad_target.vue'
import settings from './settings.vue'
import articles from './articles.vue'
import event from './event.vue'
import link from './link.vue'
import ok from './ok.vue'
import vk from './vk.vue'
import fb from './fb.vue'
import viber from './viber.vue'
import twitter from './twitter.vue'
import tg from './tg.vue'
import yaZen from './ya-zen.vue'
import googleDiscovery from './google-discovery.vue'
import pulseMail from './pulse-mail.vue'
import yandex from './yandex.vue'
import google from './google.vue'
import facebook from './facebook.vue'
import vkontakte from './vkontakte.vue'
import youtube from './youtube.vue'
import instagram from './instagram.vue'
import chevronDown from './chevron-down.vue'
import chevronLeft from './chevron-left.vue'
import chevronRight from './chevron-right.vue'
import verstkaDesktop from './verstka-desktop.vue'
import verstkaMobile from './verstka-mobile.vue'
import maleFemale from './maleFemale.vue'
import horoscope from './horoscope.vue'
import factCheck from './factCheck.vue'
import leads from './leads.vue'
import chart from './chart.vue'
import newspaper from './newspaper.vue'
import tag from './tag.vue'
import ladle from './ladle.vue'
import ord from './ord.vue'
import download from './download.vue'
import support from './support.vue'
import podcastIcon from './podcasts-icon.vue'
import episodesIcon from './episodes-icon.vue'
import audioIcon from './audio-icon.vue'
import circleDzen from './circle-dzen.vue'
import circleFb from './circle-fb.vue'
import circleOk from './circle-ok.vue'
import circleTwitter from './circle-twitter.vue'
import circleViber from './circle-viber.vue'
import circleVk from './circle-vk.vue'
import circleYoutube from './circle-youtube.vue'
import circleTg from './circle-tg.vue'
import errorIcon from './error.vue'
import updateIcon from './update.vue'
import unknowOrientationIcon from './unknow-orientation-icon.vue'
import errorOrientationIcon from './error-orientation-icon.vue'
import calendarIcon from './calendar-icon.vue'
import newsIcon from './news-icon.vue'
import archiveIcon from './archive-icon.vue'
import recommendationIcon from './recommendation-icon.vue'
import sideToggleIcon from './side-toggle-icon.vue'
import tileGalleryIcon from './tile-gallery-icon.vue'
import verticalGalleryIcon from './vertical-gallery-icon.vue'
import zoomIcon from './zoom-icon.vue'
import commentView from './comment_view.vue'
import downloadImage from './download_image.vue'
import list from './list.vue'
import interests from './interests.vue'
import historyIcon from './historyIcon.vue'
import nativeDistribution from './native_distribution.vue'
import accountGroupOutline from './accountGroupOutline.vue'

const icons = {
  arrowLeft,
  arrowRight,
  home,
  content,
  comment,
  design,
  analytics,
  distribution,
  distributionPlan,
  marketing,
  moveDown,
  ad_target,
  settings,
  articles,
  event,
  verstkaDesktop,
  verstkaMobile,
  link,
  ok,
  vk,
  fb,
  viber,
  twitter,
  tg,
  youtube,
  yaZen,
  googleDiscovery,
  pulseMail,
  yandex,
  google,
  facebook,
  vkontakte,
  instagram,
  chevronDown,
  chevronLeft,
  chevronRight,
  maleFemale,
  horoscope,
  factCheck,
  leads,
  chart,
  newspaper,
  tag,
  ladle,
  play,
  logoBazaar,
  logoCosmo,
  logoEsquire,
  logoGoodhouse,
  logoGrazia,
  logoIm,
  logoMhealth,
  logoPopmech,
  logoRobb,
  ord,
  download,
  support,
  podcastIcon,
  episodesIcon,
  audioIcon,
  circleDzen,
  circleFb,
  circleOk,
  circleTwitter,
  circleViber,
  circleYoutube,
  circleVk,
  circleTg,
  errorIcon,
  updateIcon,
  unknowOrientationIcon,
  errorOrientationIcon,
  calendarIcon,
  newsIcon,
  archiveIcon,
  recommendationIcon,
  sideToggleIcon,
  tileGalleryIcon,
  verticalGalleryIcon,
  zoomIcon,
  commentView,
  downloadImage,
  list,
  interests,
  historyIcon,
  nativeDistribution,
  accountGroupOutline,
}

export default Object.entries(icons).reduce<
  Record<string, VuetifyIconComponent>
>((acc, [name, component]) => {
  acc[name] = {
    component,
  }
  return acc
}, {})
