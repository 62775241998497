/*
 * ============================
 * File: index.js
 * Project: Octavia-Admin
 * File Created: Thursday, 9th April 2020 2:11:05 am
 * Author:UILIB
 * AuthorUrl:https://ui-lib.com/
 * -----
 * Last Modified: Saturday, 18th April 2020 9:32:52 pm
 * Modified By: naime hossain (naime.hossain93@gmail.com)
 * -----
 * Copyright 2020 - 2021 UILIB, UILIB
 * ============================
 */

// Imports
import Vue from 'vue'
import Router, { Route } from 'vue-router'
import store from '@/store'
import { Platform, PlatformTitle, ProjectID } from '@/types/platform'
import { UserGetters } from '@/store/modules/user/types'
import { AuthGetters } from '@/store/modules/auth/types'
import {
  UserPermission,
  UserPermissionBlockField,
  UserPermissionField,
  UserPermissionPage,
} from '@/types/user'
import { Role } from '@/types/role'

const changeDocumentTitle = (route: Route) => {
  const platform = (route.params.platform || Platform.im) as Platform
  const { title = 'Sirius CMS' } = route.meta

  document.title = title.replace('%edition%', PlatformTitle[platform])
}

Vue.use(Router)

const availablePlatforms = Object.values(Platform)
const platfromParam = `:platform(${availablePlatforms.join('|')})`

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    {
      name: 'sign-in',
      path: '/sign-in/',
      component: () => import('@/views/Auth/SignIn/SignIn.vue'),
      meta: {
        layout: 'Auth',
      },
    },
    {
      name: 'forgot',
      path: '/forgot/',
      component: () => import('@/views/Auth/Recover/Recover.vue'),
      meta: {
        layout: 'Auth',
      },
    },
    {
      path: '/changepassword/',
      redirect: {
        name: 'change-password',
      },
    },
    {
      name: 'change-password',
      path: '/change-password/',
      component: () => import('@/views/Auth/ChangePassword/ChangePassword.vue'),
      meta: {
        layout: 'Auth',
      },
    },
    {
      name: 'home',
      path: '/',
      component: () => import('@/views/Home/Home.vue'),
      meta: {
        title: 'Главная панель',
        permissions: [
          {
            page: UserPermissionPage.HOME,
            list: [UserPermission.READ],
          },
        ],
      },
    },
    {
      name: 'calendar-events',
      path: '/calendar-events/',
      component: () => import('@/views/CalendarOfEvents/CalendarOfEvents.vue'),
      meta: {
        title: 'Календарь событий',
        permissions: [
          {
            page: UserPermissionPage.CALENDAR_EVENTS,
            list: [UserPermission.READ],
          },
        ],
      },
    },
    {
      name: 'user_list',
      path: '/user/',
      meta: {
        breadcrumb: {
          name: 'Пользователи',
        },
        title: 'Пользователи - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.USER,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/UserList/UserList.vue'),
    },
    {
      name: 'user_edit',
      path: '/:platform?/user/edit/:id/',
      meta: {
        title: 'Редактирование - Пользователи - %edition% - Sirius CMS',
        appSidebarOpened: false,
        only_admin: true,
        permissions: [
          {
            page: UserPermissionPage.USER,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.USER,
            list: [UserPermission.EDIT],
          },
        ],
      },
      component: () => import('@/views/UserEdit/UserEdit.vue'),
    },
    {
      name: 'user_roles',
      path: '/user/roles/:platform?/',
      meta: {
        breadcrumb: {
          name: 'Роли пользователей',
        },
        title: 'Роли пользователей - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.USER,
            list: [UserPermission.DELETE],
          },
        ],
      },
      component: () => import('@/views/UserRoles/UserRoles.vue'),
    },
    {
      name: 'home_platform',
      path: '/:platform/',
      meta: {
        breadcrumb: {
          name: 'Главная',
        },
        title: '%edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.ARTICLE,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.RECIPE,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.BRAND,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.COMMENT,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.TOPIC,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/HomePlatform/HomePlatform.vue'),
    },
    {
      name: 'distribution_list',
      path: '/:platform/distribution/',
      meta: {
        breadcrumb: {
          name: 'Дистрибуция',
        },
        title: 'Дистрибуция - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.MAILING,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.DISTRIBUTION_PUSH,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/DistributionList/DistributionList.vue'),
    },
    {
      name: 'distribution_push_list',
      path: '/:platform/distribution/push/',
      meta: {
        breadcrumb: {
          name: 'Пуши на сегодня',
        },
        title: 'Пуши на сегодня - Дистрибуция - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.DISTRIBUTION_PUSH,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () =>
        import('@/views/PushNotificationsList/PushNotificationsList.vue'),
    },
    {
      name: 'distribution_plan_list',
      path: '/:platform/distribution/social-plan/',
      meta: {
        breadcrumb: {
          name: 'План дистрибуции',
        },
        title: 'План дистрибуции - Дистрибуция - %edition% - Sirius CMS',
      },
      component: () =>
        import('@/views/DistributionPlanList/DistributionPlanList.vue'),
    },
    {
      name: 'distribution_native_post_create',
      path: '/:platform/distribution/social-plan/post/create/',
      meta: {
        breadcrumb: {
          name: 'План дистрибуции',
        },
        title:
          'Создание нативного поста - Дистрибуция - %edition% - Sirius CMS',
        noGutters: true,
        appSidebar: false,
        appSidebarOpened: false,
      },
      component: () => import('@/views/NativePost/NativePost.vue'),
    },
    {
      name: 'distribution_native_post_edit',
      path: '/:platform/distribution/social-plan/post/:id(\\d+)/',
      meta: {
        breadcrumb: {
          name: 'План дистрибуции',
        },
        title:
          'Редактирование нативного поста - Дистрибуция - %edition% - Sirius CMS',
        noGutters: true,
        appSidebar: false,
        appSidebarOpened: false,
      },
      component: () => import('@/views/NativePost/NativePost.vue'),
    },
    {
      name: 'distribution_mailing_list',
      path: '/:platform/distribution/mailing/',
      meta: {
        breadcrumb: {
          name: 'Рассылки',
        },
        title: 'Рассылки - Дистрибуция - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.MAILING,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/MailingList/MailingList.vue'),
    },
    {
      name: 'distribution_mailing_create',
      path: '/:platform/distribution/mailing/create/',
      meta: {
        breadcrumb: {
          name: 'Создать рассылку',
        },
        title: 'Создать рассылку - %edition% - Sirius CMS',
        noGutters: true,
        appSidebar: false,
        appSidebarOpened: false,
        permissions: [
          {
            page: UserPermissionPage.MAILING,
            list: [UserPermission.CREATE],
          },
        ],
      },
      component: () => import('@/views/MailingDetail/MailingDetail.vue'),
    },
    {
      name: 'distribution_mailing_edit',
      path: '/:platform/distribution/mailing/:id(\\d+)/',
      meta: {
        breadcrumb: {
          name: 'Редактирование рассылки',
        },
        title: 'Редактирование рассылки - %edition% - Sirius CMS',
        noGutters: true,
        appSidebar: false,
        appSidebarOpened: false,
        permissions: [
          {
            page: UserPermissionPage.MAILING,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.MAILING,
            list: [UserPermission.EDIT],
          },
        ],
      },
      component: () => import('@/views/MailingDetail/MailingDetail.vue'),
    },
    {
      name: 'distribution_mailing_interest_list',
      path: '/:platform/distribution/mailing/interest/',
      meta: {
        breadcrumb: {
          name: 'Интересы',
        },
        title: 'Интересы - Рассылки - Дистрибуция - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.MAILING_INTEREST,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () =>
        import(
          '@/views/DistributionMailingInterestList/DistributionMailingInterestList.vue'
        ),
    },
    {
      name: 'distribution_mailing_preview',
      path: '/:platform/distribution/mailing/:id(\\d+)?/preview',
      meta: {
        breadcrumb: {
          name: 'Предпросмотр рассылки',
        },
        title: 'Предпросмотр рассылки - %edition% - Sirius CMS',
        noGutters: true,
        appSidebar: false,
        appSidebarOpened: false,
        permissions: [
          {
            page: UserPermissionPage.MAILING,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.MAILING,
            list: [UserPermission.EDIT],
          },
        ],
      },
      component: () => import('@/views/MailingDetail/MailingDetail.vue'),
    },
    {
      name: 'comments',
      path: '/:platform/comments/',
      meta: {
        breadcrumb: {
          name: 'Комментарии',
        },
        title: 'Комментарии - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.COMMENT,
            list: [UserPermission.READ, UserPermission.EDIT],
          },
        ],
      },
      component: () => import('@/views/CommentsContent/CommentsContent.vue'),
    },
    {
      name: 'main-comments',
      path: '/comment/',
      meta: {
        breadcrumb: {
          name: 'Комментарии',
        },
        title: 'Комментарии - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.COMMENT,
            list: [UserPermission.READ, UserPermission.EDIT],
          },
        ],
      },
      component: () => import('@/views/CommentsContent/CommentsContent.vue'),
    },
    {
      name: 'main-comments_moderation',
      path: '/comment/moderation/',
      meta: {
        breadcrumb: {
          name: 'Модерация',
        },
        title: 'Модерация - %edition% - Sirius CMS',
        noGutters: true,
        appSidebar: false,
        appSidebarOpened: false,
        permissions: [
          {
            page: UserPermissionPage.COMMENT,
            list: [UserPermission.READ, UserPermission.EDIT],
          },
        ],
      },
      component: () =>
        import('@/views/CommentsModeration/CommentsModeration.vue'),
    },
    {
      name: 'articles-for-comments',
      path: '/comment/articles/',
      meta: {
        breadcrumb: {
          name: 'Модерация',
        },
        title: 'Модерация - %edition% - Sirius CMS',
        noGutters: true,
        appSidebar: false,
        appSidebarOpened: false,
      },
      component: () => import('@/views/CommentArticles/CommentArticles.vue'),
    },
    {
      name: 'comments_list',
      path: '/:platform/comments/list',
      meta: {
        breadcrumb: {
          name: 'Комментарии',
        },
        title: 'Комментарии - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.COMMENT,
            list: [UserPermission.READ, UserPermission.EDIT],
          },
        ],
      },
      component: () => import('@/views/CommentList/CommentList.vue'),
    },
    {
      name: 'comments_moderation',
      path: '/:platform/comment/moderation',
      meta: {
        breadcrumb: {
          name: 'Модерация',
        },
        title: 'Модерация - %edition% - Sirius CMS',
        noGutters: true,
        appSidebar: false,
        appSidebarOpened: false,
        permissions: [
          {
            page: UserPermissionPage.COMMENT,
            list: [UserPermission.READ, UserPermission.EDIT],
          },
        ],
      },
      component: () =>
        import('@/views/CommentsModeration/CommentsModeration.vue'),
    },
    {
      name: 'settings',
      path: '/:platform/settings/',
      meta: {
        breadcrumb: {
          name: 'Настройки',
        },
        title: 'Настройки - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.SETTINGS_STATIC,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.USER,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.SETTINGS_CONTACTS,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/SettingsPage/SettingsPage.vue'),
    },
    {
      name: 'support',
      path: '/:platform/support',

      meta: {
        breadcrumb: {
          name: 'Поддержка',
        },
        noGutters: true,
      },
      component: () => import('@/views/SupportPage/SupportPage.vue'),
    },
    {
      name: 'support-main',
      path: '/support',
      meta: {
        breadcrumb: {
          name: 'Поддержка',
        },
        noGutters: true,
      },
      component: () => import('@/views/SupportPage/SupportPage.vue'),
    },
    {
      name: 'settings_static',
      path: '/:platform/settings/static/',
      meta: {
        breadcrumb: {
          name: 'Технические страницы',
        },
        title: 'Технические страницы - Настройки - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.SETTINGS_STATIC,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () =>
        import('@/views/TechnicalArticles/TechnicalArticles.vue'),
    },
    {
      name: 'settings_static_edit',
      path: '/:platform/settings/static/edit/:id/',
      meta: {
        title:
          'Редактирование технической страницы - Технические страницы - Настройки - %edition% - Sirius CMS',
        appSidebarOpened: false,
        appSidebar: false,
        permissions: [
          {
            page: UserPermissionPage.SETTINGS_STATIC,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.SETTINGS_STATIC,
            list: [UserPermission.EDIT],
          },
        ],
      },
      component: () =>
        import('@/views/TechnicalArticleEdit/TechnicalArticleEdit.vue'),
    },
    {
      name: 'settings_contacts',
      path: '/:platform/settings/contacts/',
      meta: {
        breadcrumb: {
          name: 'Контакты',
        },
        title: 'Контакты - Настройки - %edition% - Sirius CMS',
        appSidebar: false,
        appSidebarOpened: false,
        permissions: [
          {
            page: UserPermissionPage.SETTINGS_CONTACTS,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/ContactList/ContactList.vue'),
    },
    {
      name: 'settings_user_edit',
      path: '/:platform/settings/user/edit/:id/',
      meta: {
        title:
          'Редактирование пользователя - Настройки - %edition% - Sirius CMS',
        appSidebarOpened: false,
        only_admin: true,
        permissions: [
          {
            page: UserPermissionPage.USER,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.USER,
            list: [UserPermission.EDIT],
          },
        ],
      },
      component: () => import('@/views/UserEdit/UserEdit.vue'),
    },
    {
      name: 'settings_user_private',
      path: '/:platform/settings/private/',
      meta: {
        breadcrumb: {
          name: 'Индивидуальные настройки',
        },
        title: 'Технические страницы - Настройки - Sirius CMS',
      },
      component: () => import('@/views/PrivateSettings/PrivateSettings.vue'),
    },
    {
      name: 'user_detail',
      path: '/:platform?/user/:id/',
      meta: {
        title: '%fullname% - Пользователи - %edition% - Sirius CMS',
        appSidebarOpened: false,
        permissions: [
          {
            page: UserPermissionPage.USER,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.USER,
            list: [UserPermission.EDIT],
          },
        ],
      },
      component: () => import('@/views/UserDetail/UserDetail.vue'),
    },
    {
      name: 'settings_user_detail',
      path: '/:platform/settings/user/:id/',
      meta: {
        title: '%fullname% - Пользователи - Настройки - %edition% - Sirius CMS',
        appSidebarOpened: false,
        permissions: [
          {
            page: UserPermissionPage.USER,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.USER,
            list: [UserPermission.EDIT],
          },
        ],
      },
      component: () => import('@/views/UserDetail/UserDetail.vue'),
    },
    {
      name: 'settings_users',
      path: '/:platform/settings/user/',
      meta: {
        breadcrumb: {
          name: 'Пользователи',
        },
        title: 'Пользователи - Настройки - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.USER,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/UserList/UserList.vue'),
    },
    {
      name: 'content',
      path: '/:platform/content/',
      meta: {
        breadcrumb: {
          name: 'Контент',
        },
        title: 'Контент - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.ARTICLE,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.PUBLICATION_PLAN,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.RECIPE,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.SECTION,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.TAG,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.AUTHOR,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.EXPERT,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.WIDGET,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.ISSUE,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.BRAND,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.TOPIC,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/ContentPage/ContentPage.vue'),
    },
    {
      name: 'gpt',
      path: '/:platform/content/gpt',
      meta: {
        title: 'YandexGPT На странице',
      },
      component: () => import('@/views/YandexGPT/YandexGPT.vue'),
    },
    {
      name: 'content_publication_plan',
      path: '/:platform/content/plan/:mode(news|articles|video|seo|republished)?',
      meta: {
        breadcrumb: {
          name: 'План публикаций',
        },
        title: 'План публикаций - Контент - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.PUBLICATION_PLAN,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () =>
        import('@/views/StatisticsProject/StatisticsProject.vue'),
    },
    {
      name: 'content_recipe_page',
      path: '/:platform/content/recipe/',
      meta: {
        breadcrumb: {
          name: 'Рецепты',
        },
        title: 'Рецепты - Контент - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.RECIPE,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.RECIPE_PRODUCT,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.RECIPE_DISH,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.RECIPE_INGREDIENT,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/RecipePage/RecipePage.vue'),
    },
    {
      name: 'content_recipe_list',
      path: '/:platform/content/recipe/recipes/',
      meta: {
        breadcrumb: {
          name: 'Рецепты',
        },
        title: 'Рецепты - Рецепты - Контент - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.RECIPE,
            list: [UserPermission.READ],
          },
        ],
        noGutters: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "RecipeListView" */ '@/views/RecipeList/RecipeList.vue'
        ),
    },
    {
      name: 'content_recipe_product_list',
      path: '/:platform/content/recipe/product/',
      meta: {
        breadcrumb: {
          name: 'Продукты',
        },
        title: 'Продукты - Рецепты - Контент - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.RECIPE_PRODUCT,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () =>
        import('@/views/RecipeProductList/RecipeProductList.vue'),
    },
    {
      name: 'content_recipe_product_edit',
      path: '/:platform/content/recipe/product/:id/',
      meta: {
        breadcrumb: {
          name: 'Редактирование продукта',
        },
        title:
          'Редактирование продукта - Продукты - Рецепты - Контент - %edition% - Sirius CMS',
        noGutters: true,
        appSidebar: false,
        appSidebarOpened: false,
        permissions: [
          {
            page: UserPermissionPage.RECIPE_PRODUCT,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.RECIPE_PRODUCT,
            list: [UserPermission.EDIT],
          },
        ],
      },
      component: () =>
        import('@/views/RecipeProductEdit/RecipeProductEdit.vue'),
    },
    {
      name: 'content_recipe_dish_list',
      path: '/:platform/content/recipe/dish/',
      meta: {
        breadcrumb: {
          name: 'Категории блюд',
        },
        title: 'Категории блюд - Рецепты - Контент - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.RECIPE_DISH,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/RecipeDishList/RecipeDishList.vue'),
    },
    {
      name: 'content_recipe_dish_edit',
      path: '/:platform/content/recipe/dish/:id/',
      meta: {
        breadcrumb: {
          name: 'Редактирование категории блюда',
        },
        title:
          'Редактирование категории блюда - Категории блюд - Рецепты - Контент - %edition% - Sirius CMS',
        noGutters: true,
        appSidebar: false,
        appSidebarOpened: false,
        permissions: [
          {
            page: UserPermissionPage.RECIPE_DISH,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.RECIPE_DISH,
            list: [UserPermission.EDIT],
          },
        ],
      },
      component: () => import('@/views/RecipeDishEdit/RecipeDishEdit.vue'),
    },

    {
      name: 'content_recipe_norm-ingredient_list',
      path: '/:platform/content/recipe/norm-ingredient/',
      meta: {
        breadcrumb: {
          name: 'Нормализованные ингредиенты',
        },
        title:
          'Нормализованные ингредиенты - Рецепты - Контент - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.RECIPE_INGREDIENT,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () =>
        import('@/views/RecipeNormIngredientList/RecipeNormIngredientList.vue'),
    },
    {
      name: 'content_recipe_norm-ingredient_edit',
      path: '/:platform/content/recipe/norm-ingredient/:id/',
      meta: {
        breadcrumb: {
          name: 'Редактирование нормализованного ингредиента',
        },
        title:
          'Редактирование нормализованного ингредиента - Нормализованные ингредиенты - Рецепты - Контент - %edition% - Sirius CMS',
        noGutters: true,
        appSidebar: false,
        appSidebarOpened: false,
        permissions: [
          {
            page: UserPermissionPage.RECIPE_INGREDIENT,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.RECIPE_INGREDIENT,
            list: [UserPermission.EDIT],
          },
        ],
      },
      component: () =>
        import('@/views/RecipeNormIngredientEdit/RecipeNormIngredientEdit.vue'),
    },
    {
      name: 'content_widget_test',
      path: '/:platform/content/widget/test/',
      meta: {
        breadcrumb: {
          name: 'Тесты',
        },
        title: 'Тесты - Виджеты - Контент - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.WIDGET,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/TestList/TestList.vue'),
    },
    {
      name: 'content_widget_recipe_selection',
      path: '/:platform/content/widget/recipe-selection/',
      meta: {
        breadcrumb: {
          name: 'Подборки рецептов',
        },
        title: 'Подборки рецептов - Виджеты - Контент - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.WIDGET,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/RecipeSelection/RecipeSelection.vue'),
    },
    {
      name: 'content_widget_recipe_selection_edit',
      path: '/:platform/content/widget/recipe-selection/edit/:id/',
      meta: {
        title:
          'Редактирование подборки - Виджеты - Контент - %edition% - Sirius CMS',
        appSidebarOpened: false,
        permissions: [
          {
            page: UserPermissionPage.WIDGET,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.WIDGET,
            list: [UserPermission.EDIT],
          },
        ],
      },
      component: () =>
        import('@/views/RecipeSelectionEdit/RecipeSelectionEdit.vue'),
    },
    {
      name: 'content_widget_test_edit',
      path: '/:platform/content/widget/test/edit/:id/',
      meta: {
        title:
          'Редактирование теста - Виджеты - Контент - %edition% - Sirius CMS',
        appSidebarOpened: false,
        permissions: [
          {
            page: UserPermissionPage.WIDGET,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.WIDGET,
            list: [UserPermission.EDIT],
          },
        ],
      },
      component: () =>
        import(
          '@/views/ArticleDetail/components/ArticleWidget/Widgets/Test/TestEdit/TestEdit.vue'
        ),
    },
    {
      name: 'content_articles',
      path: '/:platform/content/article/',
      meta: {
        breadcrumb: {
          name: 'Статьи',
        },
        title: 'Статьи - Контент - %edition% - Sirius CMS',
        noGutters: true,
        permissions: [
          {
            page: UserPermissionPage.ARTICLE,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () =>
        import(
          /* webpackChunkName: "ArticlesListView" */
          '@/views/ArticlesList/ArticlesList.vue'
        ),
    },
    {
      name: 'content_issue',
      path: '/:platform/content/issue/',
      meta: {
        breadcrumb: {
          name: 'Журналы',
        },
        title: 'Журналы - Контент - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.ISSUE,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/IssueList/IssueList.vue'),
    },
    {
      name: 'content_issue_edit',
      path: '/:platform/content/issue/edit/:id/',
      meta: {
        title:
          'Редактирование журнала - Журналы - Контент - %edition% - Sirius CMS',
        appSidebar: false,
        appSidebarOpened: false,
        permissions: [
          {
            page: UserPermissionPage.ISSUE,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.ISSUE,
            list: [UserPermission.EDIT],
          },
        ],
      },
      component: () => import('@/views/IssueEdit/IssueEdit.vue'),
    },
    {
      name: 'content_article',
      path: '/:platform/content/article/:id/',
      meta: {
        title:
          'Редактирование статьи - Статьи - Контент - %edition% - Sirius CMS',
        noGutters: true,
        appSidebar: false,
        appSidebarOpened: false,
        permissions: [
          {
            page: UserPermissionPage.ARTICLE,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.ARTICLE,
            list: [UserPermission.EDIT],
          },
        ],
      },
      component: () =>
        import(
          /* webpackChunkName: "ArticleDetailView" */
          '@/views/ArticleDetail/ArticleDetail.vue'
        ),
    },
    {
      name: 'content_recipe',
      path: '/:platform/content/recipe/edit/:id/',
      meta: {
        title: 'Редактирование рецепта - Контент - %edition% - Sirius CMS',
        noGutters: true,
        appSidebarOpened: false,
        permissions: [
          {
            page: UserPermissionPage.RECIPE,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.RECIPE,
            list: [UserPermission.EDIT],
          },
        ],
      },
      component: () => import('@/views/RecipeEdit/RecipeEdit.vue'),
    },
    {
      name: 'content_tag',
      path: '/:platform/content/tag/',
      meta: {
        breadcrumb: {
          name: 'Теги',
        },
        title: 'Теги - Контент - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.TAG,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/TagList/TagList.vue'),
    },
    {
      name: 'content_tag_edit',
      path: '/:platform/content/tag/edit/:id/',
      meta: {
        title: 'Редактирование - Теги - Контент - %edition% - Sirius CMS',
        noGutters: true,
        appSidebar: false,
        appSidebarOpened: false,
        permissions: [
          {
            page: UserPermissionPage.TAG,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.TAG,
            list: [UserPermission.EDIT],
          },
        ],
      },
      component: () => import('@/views/TagEdit/TagEdit.vue'),
    },
    {
      name: 'content_author',
      path: '/:platform/content/author/',
      meta: {
        breadcrumb: {
          name: 'Авторы',
        },
        title: 'Авторы - Контент - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.AUTHOR,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/AuthorList/AuthorList.vue'),
    },
    {
      name: 'content_expert_list',
      path: '/:platform/content/expert/',
      meta: {
        breadcrumb: {
          name: 'Эксперты',
        },
        title: 'Эксперты - Контент - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.EXPERT,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/ExpertsList/ExpertsList.vue'),
    },
    {
      name: 'content_expert_edit',
      path: '/:platform/content/expert/edit/:id/',
      meta: {
        breadcrumb: {
          name: 'Редактирование эксперта',
        },
        title:
          'Редактирование эксперта - Эксперты - Контент - %edition% - Sirius CMS',
        noGutters: true,
        appSidebar: false,
        appSidebarOpened: false,
        permissions: [
          {
            page: UserPermissionPage.EXPERT,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.EXPERT,
            list: [UserPermission.EDIT],
          },
        ],
      },

      component: () => import('@/views/ExpertEdit/ExpertEdit.vue'),
    },
    {
      name: 'content_widget_poll',
      path: '/:platform/content/widget/poll/',
      meta: {
        breadcrumb: {
          name: 'Опросы',
        },
        title: 'Опросы - Виджеты - Контент - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.WIDGET,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.TOPIC,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/PollList/PollList.vue'),
    },
    {
      name: 'content_widget_poll_edit',
      path: '/:platform/content/widget/poll/edit/:id/',
      meta: {
        title:
          'Редактирование опроса - Виджеты - Контент - %edition% - Sirius CMS',
        appSidebarOpened: false,
        permissions: [
          {
            page: UserPermissionPage.WIDGET,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.WIDGET,
            list: [UserPermission.EDIT],
          },
        ],
      },
      component: () =>
        import(
          '@/views/ArticleDetail/components/ArticleWidget/Widgets/Poll/PollEdit/PollEdit.vue'
        ),
    },
    {
      name: 'content_author_edit',
      path: '/:platform/content/author/edit/:id/',
      meta: {
        breadcrumb: {
          name: 'Редактирование автора',
        },
        title: '%NameAuthor% - Авторы - Контент - %edition% - Sirius CMS',
        appSidebarOpened: false,
        permissions: [
          {
            page: UserPermissionPage.AUTHOR,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.AUTHOR,
            list: [UserPermission.EDIT],
          },
        ],
      },
      component: () => import('@/views/AuthorEdit/AuthorEdit.vue'),
    },
    {
      name: 'content_section_edit',
      path: '/:platform/content/section/edit/:id/',
      meta: {
        title:
          'Редактирование рубрики - Рубрики - Контент - %edition% - Sirius CMS',
        appSidebar: false,
        noGutters: true,
        appSidebarOpened: false,
        permissions: [
          {
            page: UserPermissionPage.SECTION,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.SECTION,
            list: [UserPermission.EDIT],
          },
        ],
      },
      component: () => import('@/views/SectionEdit/SectionEdit.vue'),
    },
    {
      name: 'content_section',
      path: '/:platform/content/section/',
      meta: {
        breadcrumb: {
          name: 'Рубрики',
        },
        title: 'Рубрики - Контент - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.SECTION,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/SectionList/SectionList.vue'),
    },
    {
      name: 'content_widget_gallery',
      path: '/:platform/content/widget/gallery/',
      meta: {
        breadcrumb: {
          name: 'Галереи',
        },
        title: 'Галереи - Виджеты - Контент - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.WIDGET,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.GALLERY,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/GalleryList/GalleryList.vue'),
    },
    {
      name: 'content_widget_gallery_edit',
      path: '/:platform/content/widget/gallery/edit/:id/',
      meta: {
        title:
          'Редактирование - Галереи - Виджеты - Контент - %edition% - Sirius CMS',
        appSidebar: false,
        appSidebarOpened: false,
        noGutters: true,
        permissions: [
          {
            page: UserPermissionPage.WIDGET,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.WIDGET,
            list: [UserPermission.EDIT],
          },
          {
            page: UserPermissionPage.GALLERY,
            list: [UserPermission.READ, UserPermission.EDIT],
          },
        ],
      },
      component: () => import('@/views/GalleryEdit/GalleryEdit.vue'),
    },
    {
      name: 'analytics',
      path: '/:platform/analytics/',
      meta: {
        breadcrumb: {
          name: 'Аналитика',
        },
        title: 'Аналитика - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.ANALYTICS_CONTENT,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.ANALYTICS_GENERAL,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.ANALYTICS_SEARCH_ENGINES,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.ANALYTICS_SUMMARY,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/AnalyticsPage/AnalyticsPage.vue'),
    },
    {
      name: 'analytics_public',
      path: '/:platform/analytics/publication/',
      meta: {
        breadcrumb: {
          name: 'Публикации',
        },
        title: 'Публикации - Аналитика - %edition% - Sirius CMS',
      },
      component: () =>
        import('@/views/AnalyticsPublications/AnalyticsPublications.vue'),
    },
    {
      name: 'analytics_authors',
      path: '/:platform/analytics/authors/',
      meta: {
        breadcrumb: {
          name: 'Авторы',
        },
        title: 'Авторы - Аналитика - %edition% - Sirius CMS',
      },
      component: () => import('@/views/AnalyticsAuthors/AnalyticsAuthors.vue'),
    },
    {
      name: 'analytics_search_engines',
      path: '/:platform/analytics/search-engines/',
      meta: {
        breadcrumb: {
          name: 'Поисковые системы',
        },
        title: 'Поисковые системы - Аналитика - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.ANALYTICS_SEARCH_ENGINES,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/AnalyticsSeo/AnalyticsSeo.vue'),
    },
    {
      name: 'recommendation',
      path: '/:platform/recommendation',
      meta: {
        breadcrumb: {
          name: 'Рекомендации',
        },
        title: 'Рекомендации',
      },
      component: () => import('@/views/Recomendation/Recomendation.vue'),
    },
    {
      name: 'recommendation-calendar',
      path: '/:platform/recommendation/calendar',
      meta: {
        breadcrumb: {
          name: 'Календарь',
        },
        title: 'Рекомендации - Календарь',
      },
      component: () => import('@/views/CalendarOfEvents/CalendarOfEvents.vue'),
    },
    {
      name: 'news-bulletin',
      path: '/:platform/recommendation/news-bulletin',
      meta: {
        breadcrumb: {
          name: 'Сводка новостей',
        },
        title: 'Рекомендации - Сводка новостей',
      },
      component: () => import('@/views/NewsBulletin/NewsBulletin.vue'),
    },
    {
      name: 'archive',
      path: '/:platform/recommendation/archive',
      meta: {
        breadcrumb: {
          name: 'Архив',
        },
        title: 'Рекомендации - Архив',
      },
      component: () => import('@/views/Archive/Archive.vue'),
    },
    {
      name: 'adv_tag',
      path: '/:platform/adv/tag/',
      meta: {
        breadcrumb: {
          name: 'Реклама по тегу',
        },
        title: 'Реклама по тегу - Реклама и таргетинг - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.ADV_TAG,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/AdvTagList/AdvTagList.vue'),
    },
    {
      name: 'adv_tag_edit',
      path: '/:platform/adv/tag/edit/:id/',
      meta: {
        title:
          'Редактирование - Реклама по тегу - Реклама и таргетинг - %edition% - Sirius CMS',
        appSidebarOpened: false,
        permissions: [
          {
            page: UserPermissionPage.ADV_TAG,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.ADV_TAG,
            list: [UserPermission.EDIT],
          },
        ],
      },
      component: () => import('@/views/AdvTagEdit/AdvTagEdit.vue'),
    },
    {
      name: 'adv_switch',
      path: '/:platform/adv/switch/',
      meta: {
        title:
          'Переключение баннеров - Реклама и таргетинг - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.ADV_SWITCH,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/AdvSwitchList/AdvSwitchList.vue'),
    },
    {
      name: 'adv_ord',
      path: '/:platform/adv/ord/',
      meta: {
        noGutters: true,
        title: 'Рекламные материалы',
        permissions: [
          {
            page: UserPermissionPage.ADV_TAG,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/AdvOrdList/AdvOrdList.vue'),
    },
    {
      name: 'content_widget_fact',
      path: '/:platform/content/widget/fact/',
      meta: {
        breadcrumb: {
          name: 'Факты',
        },
        title: 'Факты - Виджеты - Контент - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.WIDGET,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/FactList/FactList.vue'),
    },
    {
      name: 'content_widget',
      path: '/:platform/content/widget/',
      meta: {
        breadcrumb: {
          name: 'Виджеты',
        },
        title: 'Виджеты - Контент - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.WIDGET,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.BRAND,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.TOPIC,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () =>
        import('@/views/ContentWidgetsList/ContentWidgetsList.vue'),
    },
    {
      name: 'design',
      path: '/:platform/design/',
      meta: {
        title: 'Дизайн - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.POPBLOCK,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.FEATURE,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.POPUP,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.DESIGN_BLOCK,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.DESIGN_CONTEST,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.DESIGN_FIXED,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/DesignContent/DesignContent.vue'),
    },
    {
      name: 'marketing',
      path: '/:platform/marketing/',
      meta: {
        breadcrumb: {
          name: 'Маркетинг',
        },
        title: 'Маркетинг - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.MARKETING_SEO,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/MarketingList/MarketingList.vue'),
    },
    {
      name: 'marketing_seo',
      path: '/:platform/marketing/seo/',
      meta: {
        breadcrumb: {
          name: 'SEO',
        },
        title: 'SEO - Маркетинг - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.MARKETING_SEO,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/MarketingSeo/MarketingSeo.vue'),
    },
    {
      name: 'adv',
      path: '/:platform/adv/',
      meta: {
        breadcrumb: {
          name: 'Реклама и таргетинг',
        },
        title: 'Реклама и таргетинг - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.ADV_SWITCH,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.ADV_TAG,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/AdvList/AdvList.vue'),
    },
    {
      name: 'content_widget_fact_edit',
      path: '/:platform/content/widget/fact/edit/:id/',
      meta: {
        title:
          'Редактирование факта - Факты - Виджеты - Контент - %edition% - Sirius CMS',
        appSidebarOpened: false,
        permissions: [
          {
            page: UserPermissionPage.WIDGET,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.WIDGET,
            list: [UserPermission.EDIT],
          },
        ],
      },
      component: () => import('@/views/FactEdit/FactEdit.vue'),
    },
    {
      name: 'design_homepage_block',
      path: '/:platform/design/block/',
      meta: {
        breadcrumb: {
          name: 'Обвесы',
        },
        title: 'Обвесы - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.DESIGN_BLOCK,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/DesignBlockList/DesignBlockList.vue'),
    },
    {
      name: 'design_homepage_fixed',
      path: '/:platform/design/fixed/',
      meta: {
        breadcrumb: {
          name: 'Закрепы на главной',
        },
        title: 'Закрепы на главной - Дизайн - %edition% - Sirius CMS',
        appSidebar: false,
        appSidebarOpened: false,
        permissions: [
          {
            page: UserPermissionPage.DESIGN_FIXED,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/ZakrepyEdit/ZakrepyEdit.vue'),
    },
    {
      name: 'design_homepage_video-fixed',
      path: '/:platform/design/video-fixed/',
      meta: {
        breadcrumb: {
          name: 'Закрепы для видео',
        },
        title: 'Закрепы для видео - Дизайн - %edition% - Sirius CMS',
        appSidebar: false,
        appSidebarOpened: false,
        permissions: [
          {
            page: UserPermissionPage.DESIGN_FIXED,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/VideoFixed/VideoFixed.vue'),
    },
    {
      name: 'design_homepage_fixed_section',
      path: '/:platform/design/fixed-section/',
      meta: {
        breadcrumb: {
          name: 'Закрепы в разделах',
        },
        title: 'Закрепы в разделах - Дизайн - %edition% - Sirius CMS',
        appSidebar: false,
        appSidebarOpened: false,
        permissions: [
          {
            page: UserPermissionPage.DESIGN_FIXED,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/ZakrepyEdit/ZakrepyEdit.vue'),
    },
    {
      name: 'design_homepage_fixed_brands',
      path: '/:platform/design/brands/fixed/',
      meta: {
        breadcrumb: {
          name: 'Закрепы для брендов',
        },
        title: 'Закрепы для брендов - Дизайн - %edition% - Sirius CMS',
        appSidebar: false,
        appSidebarOpened: false,
        permissions: [
          {
            page: UserPermissionPage.DESIGN_FIXED,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/ZakrepyEdit/ZakrepyEdit.vue'),
    },
    {
      name: 'design_homepage_fixed_popblocks',
      path: '/:platform/design/fixed/popblocks/',
      meta: {
        breadcrumb: {
          name: 'Попблоки',
        },
        title: 'Попблоки - Закрепления - Дизайн - %edition% - Sirius CMS',
        noGutters: true,
      },
      component: () => import('@/views/FixedPopblocks/FixedPopblocks.vue'),
    },
    {
      name: 'analytics_summary',
      path: '/:platform/analytics/summary/',
      meta: {
        breadcrumb: {
          name: 'Сводка',
        },
        title: 'Сводка - Аналитика - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.ANALYTICS_SUMMARY,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () =>
        import('@/views/AnalyticsSummary/AnalyticsSummaryNew.vue'),
    },
    {
      name: 'analytics_content-type',
      path: '/:platform/analytics/content-type/',
      meta: {
        breadcrumb: {
          name: 'Виды контента',
        },
        title: 'Виды контента - Аналитика - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.ANALYTICS_SUMMARY,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () =>
        import('@/views/AnalyticsContentType/AnalyticsContentType.vue'),
    },
    {
      name: 'analytics_basic-index',
      path: '/:platform/analytics/basic-index/',
      meta: {
        breadcrumb: {
          name: 'Основные показатели',
        },
        title: 'Основные показатели - Аналитика - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.ANALYTICS_SUMMARY,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () =>
        import('@/views/AnalyticsBasicIndex/AnalyticsBasicIndex.vue'),
    },
    {
      name: 'analytics_articles',
      path: '/:platform/analytics/articles/',
      meta: {
        breadcrumb: {
          name: 'Статьи',
        },
        title: 'Статьи - Аналитика - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.ANALYTICS_SUMMARY,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () =>
        import('@/views/AnalyticsArticles/AnalyticsArticles.vue'),
    },
    {
      name: 'analytics_news',
      path: '/:platform/analytics/news/',
      meta: {
        breadcrumb: {
          name: 'Новости',
        },
        title: 'Новости - Аналитика - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.ANALYTICS_SUMMARY,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () =>
        import('@/views/AnalyticsArticles/AnalyticsArticles.vue'),
    },
    {
      name: 'design_brands_list',
      path: '/:platform/design/brands/',
      meta: {
        breadcrumb: {
          name: 'Бренды',
        },
        title: 'Бренды - Контент - %edition% - Sirius CMS',
      },
      component: () => import('@/views/BrandsList/BrandsList.vue'),
    },
    {
      name: 'design_homepage_feature',
      path: '/:platform/design/feature/:mode(main|brands|new-year)?/',
      meta: {
        breadcrumb: {
          name: 'Фичеры',
        },
        title: 'Фичеры - Дизайн - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.FEATURE,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/FeatureList/FeatureList.vue'),
    },
    {
      name: 'design_homepage_contest',
      path: '/:platform/design/contest/:mode(main|detail|specproject)?/',
      meta: {
        breadcrumb: {
          name: 'Редакция рекомендует',
        },
        title: 'Редакция рекомендует - Дизайн - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.DESIGN_CONTEST,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/ContestList/ContestList.vue'),
    },
    {
      name: 'design_homepage_scroll_articles',
      path: '/:platform/design/scroll-articles/:mode(active|archive)?/',
      meta: {
        breadcrumb: {
          name: 'Статьи в скролле',
        },
        title: 'Статьи в скролле - Дизайн - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.BLOCK,
            field: UserPermissionBlockField.ARTICLE_SCROLL,
            list: [UserPermission.EDIT],
          },
        ],
      },
      component: () =>
        import('@/views/ArticlesScrollList/ArticlesScrollList.vue'),
    },
    {
      name: 'design_homepage_scroll_videos',
      path: '/:platform/design/scroll-videos/:mode(active|archive)?/',
      meta: {
        breadcrumb: {
          name: 'Видео в скролле',
        },
        title: 'Видео в скролле - Дизайн - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.BLOCK,
            field: UserPermissionBlockField.ARTICLE_SCROLL,
            list: [UserPermission.EDIT],
          },
        ],
      },
      component: () => import('@/views/VideoScroll/VideoScroll.vue'),
    },
    {
      name: 'design_contest_edit',
      path: '/:platform/design/contest/edit/:id/',
      meta: {
        breadcrumb: {
          name: 'Редактирование рекомендации',
        },
        title:
          'Редактирование - Редакция рекомендует - Дизайн - %edition% - Sirius CMS',
        appSidebar: false,
        appSidebarOpened: false,
        permissions: [
          {
            page: UserPermissionPage.DESIGN_CONTEST,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.DESIGN_CONTEST,
            list: [UserPermission.EDIT],
          },
        ],
      },
      component: () => import('@/views/ContestEdit/ContestEdit.vue'),
    },
    {
      name: 'design_feature_edit',
      path: '/:platform/design/feature/edit/:url(main|brands|new-year)/:id/',
      meta: {
        breadcrumb: {
          name: 'Редактирование фичера',
        },
        title: `Редактирование фичера - Фичеры - Дизайн - %edition% - Sirius CMS`,
        appSidebar: false,
        appSidebarOpened: false,
        permissions: [
          {
            page: UserPermissionPage.FEATURE,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.FEATURE,
            list: [UserPermission.EDIT],
          },
        ],
      },
      component: () => import('@/views/FeatureEdit/FeatureEdit.vue'),
    },
    {
      name: 'content_widget_video',
      path: '/:platform/content/video/',
      meta: {
        breadcrumb: {
          name: 'Видео',
        },
        title: 'Видео - Контент - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.VIDEO,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/VideoList/VideoList.vue'),
    },
    {
      name: 'content_stars',
      path: '/:platform/content/stars/',
      meta: {
        breadcrumb: {
          name: 'Звезды',
        },
        title: 'Звезды - Контент - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.ARTICLE,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/StarsList/StarsList.vue'),
    },
    {
      name: 'content_stars_edit',
      path: '/:platform/content/stars/edit/:id/',
      meta: {
        breadcrumb: {
          name: 'Редактирование звезды',
        },
        title: 'Редактирование звезды - Звезды - %edition% - Sirius CMS',
        noGutters: true,
        appSidebar: false,
        appSidebarOpened: false,
      },
      component: () => import('@/views/ArticleDetail/ArticleDetail.vue'),
    },
    {
      name: 'content_video_edit',
      path: '/:platform/content/video/edit/:id/',
      meta: {
        title: 'Редактирование - Видео - Контент - %edition% - Sirius CMS',
        noGutters: true,
        appSidebar: false,
        appSidebarOpened: false,
        permissions: [
          {
            page: UserPermissionPage.VIDEO,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.VIDEO,
            list: [UserPermission.EDIT],
          },
        ],
      },
      component: () => import('@/views/VideoDetail/VideoDetail.vue'),
    },
    {
      name: 'podcasts',
      path: '/:platform/content/podcast',
      component: () => import('@/views/Podcasts/Podcasts.vue'),
      meta: {
        permissions: [
          {
            page: UserPermissionPage.PODCAST,
            list: [UserPermission.READ],
          },
        ],
      },
    },
    {
      name: 'content_podcasts',
      path: '/:platform/content/podcast/podcasts',
      meta: {
        breadcrumb: {
          name: 'Подкаст',
        },
        title: 'Подкасты - Контент - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.PODCAST,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/Podcasts/PodcastsList/PodcastsList.vue'),
    },
    {
      name: 'content_podcast',
      path: '/:platform/content/podcast/podcasts/:id',
      meta: {
        title: 'Редактирование Подкаста - Контент - %edition% - Sirius CMS',
        noGutters: true,
        appSidebar: false,
        appSidebarOpened: false,
        permissions: [
          {
            page: UserPermissionPage.PODCAST,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () =>
        import('@/views/Podcasts/PodcastDetail/PodcastDetail.vue'),
    },
    {
      name: 'content_brands',
      path: '/:platform/content/brands',
      meta: {
        breadcrumb: {
          name: 'Бренды',
        },
        title: 'Бренды - Контент - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.BRAND,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/BrandList/BrandList.vue'),
    },
    {
      name: 'content_brands_edit',
      path: '/:platform/content/brands/:id',
      meta: {
        breadcrumb: {
          name: 'Редактирование бренда',
        },
        title: 'Редактирование Бренда - Контент - %edition% - Sirius CMS',
        noGutters: true,
        appSidebar: false,
        appSidebarOpened: false,
        permissions: [
          {
            page: UserPermissionPage.BRAND,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/ArticleDetail/ArticleDetail.vue'),
    },
    {
      name: 'discussion',
      path: '/:platform/topic/',
      meta: {
        breadcrumb: {
          name: 'Обсуждение',
        },
        title: 'Обсуждение - Контент - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.TOPIC,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.TOPIC_INTEREST,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/DiscussionList/DiscussionList.vue'),
    },
    {
      name: 'discussion_topic',
      path: '/:platform/topic/articles',
      meta: {
        breadcrumb: {
          name: 'Список топиков',
        },
        title: 'Список топиков - Контент - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.TOPIC,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/TopicList/TopicList.vue'),
    },
    {
      name: 'discussion_topic_edit',
      path: '/:platform/topic/articles/:id',
      meta: {
        breadcrumb: {
          name: 'Редактирование топика',
        },
        title: 'Редактирование топика - Контент - %edition% - Sirius CMS',
        noGutters: true,
        appSidebar: false,
        appSidebarOpened: false,
        permissions: [
          {
            page: UserPermissionPage.TOPIC,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/ArticleDetail/ArticleDetail.vue'),
    },
    {
      name: 'discussion_interests',
      path: '/:platform/topic/interests',
      meta: {
        breadcrumb: {
          name: 'Интересы',
        },
        title: 'Интересы - Контент - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.TOPIC_INTEREST,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/InterestsList/InterestsList.vue'),
    },
    {
      name: 'discussion_interests_edit',
      path: '/:platform/topic/interests/:id',
      meta: {
        breadcrumb: {
          name: 'Редактирование интереса',
        },
        title: 'Редактирование интереса - Контент - %edition% - Sirius CMS',
        noGutters: true,
        appSidebar: false,
        appSidebarOpened: false,
        permissions: [
          {
            page: UserPermissionPage.TOPIC_INTEREST,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/interestEdit/interestEdit.vue'),
    },
    {
      name: 'content_episodes',
      path: '/:platform/content/podcast/episodes/',
      meta: {
        breadcrumb: {
          name: 'Эпизод',
        },
        title: 'Эпизоды - Контент - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.PODCAST,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/Podcasts/EpisodesList/EpisodesList.vue'),
    },
    {
      name: 'content_episode',
      path: '/:platform/content/podcast/episodes/detail/:id',
      meta: {
        title: 'Редактирование Эпизода - Контент - %edition% - Sirius CMS',
        noGutters: true,
        appSidebar: false,
        appSidebarOpened: false,
        permissions: [
          {
            page: UserPermissionPage.PODCAST,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () =>
        import('@/views/Podcasts/EpisodeDetail/EpisodeDetail.vue'),
    },
    {
      name: 'design_homepage_popblock',
      path: '/:platform/design/popblock/',
      meta: {
        breadcrumb: {
          name: 'Попблок',
        },
        title: 'Попблок - Дизайн - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.POPBLOCK,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/PopblockList/PopblockList.vue'),
    },
    {
      name: 'design_homepage_popblock_edit',
      path: '/:platform/design/popblock/edit/:id/',
      meta: {
        title: 'Редактирование - Попблок - Дизайн - %edition% - Sirius CMS',
        appSidebarOpened: false,
        permissions: [
          {
            page: UserPermissionPage.POPBLOCK,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.POPBLOCK,
            list: [UserPermission.EDIT],
          },
        ],
      },
      component: () => import('@/views/PopblockEdit/PopblockEdit.vue'),
    },
    {
      name: 'design_homepage_popblock_brands',
      path: '/:platform/design/brands/popblock/',
      meta: {
        breadcrumb: {
          name: 'Попблок',
        },
        title: 'Попблок - Дизайн - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.POPBLOCK,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/PopblockList/PopblockList.vue'),
    },
    {
      name: 'design_popup',
      path: '/:platform/design/popup/',
      meta: {
        breadcrumb: {
          name: 'Плашки',
        },
        title: 'Плашки - Дизайн - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.POPUP,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/PopupList/PopupList.vue'),
    },
    {
      name: 'design_popup_setting',
      path: '/:platform/design/popup/setting',
      meta: {
        title: 'Настройки трафика - Плашки - Дизайн - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.POPUP,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/PopupSettingEdit/PopupSettingEdit.vue'),
    },
    {
      name: 'design_popup_edit',
      path: '/:platform/design/popup/edit/:id/',
      meta: {
        title: 'Редактирование - Плашки - Дизайн - %edition% - Sirius CMS',
        noGutters: true,
        appSidebar: false,
        appSidebarOpened: false,
        permissions: [
          {
            page: UserPermissionPage.POPUP,
            list: [UserPermission.READ],
          },
          {
            page: UserPermissionPage.POPUP,
            list: [UserPermission.EDIT],
          },
        ],
      },
      component: () => import('@/views/PopupEdit/PopupEdit.vue'),
    },
    {
      name: 'content_awards',
      path: '/:platform/content/awards',
      component: () => import('@/views/Awards/Awards.vue'),
      meta: {
        breadcrumb: {
          name: 'Премия женщин',
        },
        title: 'Премия женщин - Контент - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.ARTICLE,
            list: [UserPermission.READ],
          },
        ],
      },
    },
    {
      name: 'content_awards_applications_list',
      path: '/:platform/content/awards/applications',
      meta: {
        breadcrumb: {
          name: 'Заявки',
        },
        title: 'Заявки - Контент - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.ARTICLE,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () =>
        import('@/views/AwardsApplicationsList/AwardsApplicationsList.vue'),
    },
    {
      name: 'content_awards_applications_edit',
      path: '/:platform/content/awards/applications/:id/',
      meta: {
        breadcrumb: {
          name: 'Модерация заявки',
        },
        title: 'Модерация заявки - Контент - %edition% - Sirius CMS',
        noGutters: true,
        appSidebar: false,
        appSidebarOpened: false,
        permissions: [
          {
            page: UserPermissionPage.ARTICLE,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () =>
        import('@/views/AwardsApplicationsDetail/AwardsApplicationsDetail.vue'),
    },
    {
      name: 'content_awards_history_list',
      path: '/:platform/content/awards/history',
      meta: {
        breadcrumb: {
          name: 'Истории',
        },
        title: 'Истории - Контент - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.ARTICLE,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () =>
        import('@/views/AwardsHistoryList/AwardsHistoryList.vue'),
    },
    {
      name: 'content_awards_history_edit',
      path: '/:platform/content/awards/history/:id/',
      meta: {
        breadcrumb: {
          name: 'Редактирование истории',
        },
        title: 'Редактирование истории - Контент - %edition% - Sirius CMS',
        noGutters: true,
        appSidebar: false,
        appSidebarOpened: false,
        permissions: [
          {
            page: UserPermissionPage.ARTICLE,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/ArticleDetail/ArticleDetail.vue'),
    },
    {
      name: 'content_awards_jury_list',
      path: '/:platform/content/awards/jury',
      meta: {
        breadcrumb: {
          name: 'Жюри',
        },
        title: 'Жюри - Контент - %edition% - Sirius CMS',
        permissions: [
          {
            page: UserPermissionPage.ARTICLE,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/AwardsJuryList/AwardsJuryList.vue'),
    },
    {
      name: 'content_awards_jury_edit',
      path: '/:platform/content/awards/jury/:id/',
      meta: {
        breadcrumb: {
          name: 'Редактирование жюри',
        },
        title: 'Редактирование жюри - Контент - %edition% - Sirius CMS',
        noGutters: true,
        appSidebar: false,
        appSidebarOpened: false,
        permissions: [
          {
            page: UserPermissionPage.ARTICLE,
            list: [UserPermission.READ],
          },
        ],
      },
      component: () => import('@/views/AwardsJuryDetail/AwardsJuryDetail.vue'),
    },
    { path: '*', component: () => import('@/views/NotFound/NotFound.vue') },
  ].map((route) => ({
    ...route,
    path: route.path.replace('/:platform', `/${platfromParam}`),
  })),
})

router.beforeEach((to, from, next) => {
  const platform = (to.params.platform || Platform.im) as Platform
  const projectId = ProjectID[platform]

  const routeName = to.name || ''
  const isAuthRoute = ['sign-in', 'forgot', 'change-password'].includes(
    routeName
  )
  const isGlobalAdmin = store.getters['user/userIsGlobalAdmin']
  const routeForAdmin = to.meta.only_admin as boolean | undefined

  const isAuthenticated: AuthGetters['isAuthenticated'] =
    store.getters['auth/isAuthenticated']

  if (!isAuthenticated && !isAuthRoute) {
    next({ name: 'sign-in' })
    return
  }

  if (isAuthenticated && isAuthRoute) {
    next({ name: 'home' })
    return
  }

  if (routeForAdmin && !isGlobalAdmin) {
    next({ name: 'home' })
    return
  }

  const checkAccessUserPermission = store.getters[
    'user/checkAccessUserPermission'
  ] as UserGetters['checkAccessUserPermission']

  const routePermissions = to.meta.permissions as
    | {
        page: UserPermissionPage
        list: UserPermission[]
        field?: UserPermissionField
      }[]
    | undefined

  const routeAccessRoles = to.meta.accessRoles as Role[] | undefined

  if (availablePlatforms.includes(platform as Platform)) {
    store.commit('setPlatform', platform)
  }

  changeDocumentTitle(to)

  const projectAccessUserRoles: UserGetters['getProjectAccessUserRoles'] = store
    .getters[
    'user/getProjectAccessUserRoles'
  ] as UserGetters['getProjectAccessUserRoles']

  if (
    routeName === 'home' ||
    (!Array.isArray(routeAccessRoles) && !Array.isArray(routePermissions))
  ) {
    next()
  } else if (
    routeAccessRoles?.some((routeAccessRole) =>
      projectAccessUserRoles(projectId).includes(routeAccessRole)
    )
  ) {
    next()
  } else if (
    routePermissions?.some(({ page, list, field }) =>
      list.some((permission) =>
        checkAccessUserPermission({ page, projectId, permission, field })
      )
    )
  ) {
    next()
  } else {
    next({ name: 'home' })
  }
})

window.addEventListener('load', () => {
  document.head
    .querySelectorAll<HTMLLinkElement>('link[rel=prefetch]')
    .forEach((link) => {
      if (!link.relList.supports('prefetch')) {
        fetch(link.href)
      }
    })
})

export default router
